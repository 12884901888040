import classnames from "classnames";
import _ from "lodash";
import React from "react";
import { getParams, Link, navigateTo, setParams } from "../../utils/location";
import { getAppMedia, mediaTypes } from "../../utils/media";
import AppContainer from "../AppContainer";
import BackgroundImage from "../BackgroundImage";
import BrandFooter from "../BrandFooter";
import ButtonGroup from "../ButtonGroup";
import ServingOptionsIcon from "../serving-option-icon";
import * as styles from "./index.module.scss";

export default ({
  setOrderServingOption,
  availableServingOptions,
  T,
  location,
  giftRedeem,
  appStyles,
}) => {
  const params = getParams(location);
  const paramsToKeep = _.pick(params, "checkdeal", "checkitem");
  const isDuringGiftRedeem = _.get(giftRedeem, "selectedGift");

  const webAppBackground =
    getAppMedia(mediaTypes.webAppBackground, appStyles) || {};

  const branchId = params.branchId;
  if (params.servingOptionType) {
    _.defer(() =>
      navigateTo(
        setParams("/find-location", {
          servingOptionType: params.servingOptionType,
        }),
      ),
    );
  }

  const filteredServingOptionsForGiftRedeem = _.filter(availableServingOptions,
    (servingOption) => isDuringGiftRedeem && _.get(giftRedeem.selectedGift, "couponInstance.coupon.couponValidity.relevantServingOptionTypes") ? 
        _.get(giftRedeem.selectedGift, "couponInstance.coupon.couponValidity.relevantServingOptionTypes", []).includes(servingOption.type) : true);

  return (
    <AppContainer.ResponsiveWrapper fullscreen>
      <AppContainer.Content appStyles={appStyles} classNames={styles.Content}>
        <div className={styles.ServingOptionList}>
          <p>{T("How would you like to get your order?")}</p>
          <ButtonGroup
            appStyles={appStyles}
            big
            classNames={classnames(
              styles.ButtonGroup,
              styles.ButtonGroupMargin,
            )}
            style={{...(_.get(appStyles, "ServingOptionButton") ? _.get(appStyles, "ServingOptionButton") : {})}}
          >
            {_.map(filteredServingOptionsForGiftRedeem, (servingOption, index) => (
              <Link
                key={servingOption.id}
                onClick={() => setOrderServingOption(servingOption)}
                to={
                  branchId && !servingOption.needsAddress
                    ? setParams("/order", { branchId, ...paramsToKeep })
                    : setParams("/find-location", {
                        servingOptionType: servingOption.type,
                        ...paramsToKeep,
                      })
                }
                style={{
                  ...(!_.isEmpty(servingOption.buttonTextColor) && {
                    color: `#${servingOption.buttonTextColor}`,
                  }),
                  ...(!_.isEmpty(servingOption.buttonBackgroundColor) && {
                    backgroundColor: `#${servingOption.buttonBackgroundColor}`,
                    backgroundImage: "unset",
                  }),
                  ...(_.isEmpty(servingOption.buttonBackgroundColor) && _.get(appStyles, "Button.backgroundImage") && {
                    backgroundImage: `url("${_.get(appStyles, "Button.backgroundImage")}")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "round"
                  }),
                }}
                role="button"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    ...(appStyles.noIcons && { width: "100%" }),
                  }}
                >
                  <span
                    style={{
                      textAlign: appStyles.noIcons
                        ? "center"
                        : appStyles.rtl
                        ? "right"
                        : "left",
                      marginBottom: 6,
                    }}
                  >
                    {T(servingOption.servingOptionTag)}
                  </span>
                  <span
                    style={{
                      fontSize: "0.9rem",
                      ...(appStyles.noIcons
                        ? { textAlign: "center" }
                        : appStyles.rtl
                        ? { textAlign: "right", paddingLeft: 6 }
                        : { textAlign: "left", paddingRight: 6 }),
                      ...(appStyles.bodyFont && {
                        fontFamily: appStyles.bodyFont,
                      }),
                      ...(appStyles.bodyFontWeight && {
                        fontFamily: appStyles.bodyFontWeight,
                      }),
                    }}
                  >
                    {servingOption.description}
                  </span>
                </div>
                {!appStyles.noIcons && (
                  <div>
                    <ServingOptionsIcon
                      appStyles={appStyles}
                      servingOption={servingOption.type}
                    />
                  </div>
                )}
              </Link>
            ))}
          </ButtonGroup>
        </div>
      </AppContainer.Content>
      <AppContainer.SecondaryContent>
        <BackgroundImage
          noAnimation
          mediaKey={webAppBackground.imageKey}
          mediaType={mediaTypes.webAppBackground}
          sizes="1920px"
          imagePreview={webAppBackground.imagePreview}
        />
      </AppContainer.SecondaryContent>
      <BrandFooter
        T={T}
        appStyles={appStyles}
        backPath="/serving-options"
        params={getParams(location)}
      />
    </AppContainer.ResponsiveWrapper>
  );
};
