import React from "react";
import { connect } from "react-redux";
import ServingOptions from "../components/ServingOptions";
import withTranslation from "../hocs/withTranslation";
import { setServingOption } from "../store/order/actions";
import { getStyles, getAvailableServingOptions } from "../store/selectors";

const ServingOptionsPage = (props) => <ServingOptions {...props} />;

const mapStateToProps = (state, props) => {
  const { user, giftRedeem } = state;

  return {
    user,
    appStyles: getStyles(state, props),
    availableServingOptions: getAvailableServingOptions(state, props),
    giftRedeem
  };
};

const mapDispatchToProps = (dispatch) => ({
  setOrderServingOption: (servingOption) =>
    dispatch(setServingOption(servingOption)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(ServingOptionsPage));
